import { GLOBAL } from 'saddlebag-browser';
import { logError } from 'saddlebag-logger';

import { namespace } from '../constants';

let sentErrors = 0;
const logSendThreshold = 10;

const executeWithinThreshold = (fn) => {
  if (sentErrors < logSendThreshold) {
    fn();
    sentErrors += 1;
  }
};

const log = (message) =>
  executeWithinThreshold(() => {
    if (message.error) {
      logError(message.error, {
        component: 'unhandled',
        pageName: namespace,
      });
    }
  });

const $window = GLOBAL.getWindow();
$window.onerror = (message) => log(message);
$window.onunhandledrejection = (rejection) => log(String(rejection.reason));
