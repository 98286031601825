/* eslint-disable no-underscore-dangle */
import { afterDomContentLoaded } from 'saddlebag-browser';
import logger from 'saddlebag-logger';
import observer from 'saddlebag-observer';
import '@skyscanner/backpack-web/bpk-stylesheets';
import '@skyscanner/backpack-web/bpk-stylesheets/font';

import { namespace } from './constants';
import { loadExternalScripts } from './scriptLoader';
import './bootstrap/error-handling';
import './public-path';
import './unsupported-browser.scss';

const afterPageLoad = async () => {
  observer.publish('mixpanel-track', {
    name: `${namespace} Loaded`,
  });
  const { default: debugControlsInit } = await import(
    /* webpackMode: "lazy" */ './bootstrap'
  );
  debugControlsInit();
};

(async () => {
  try {
    await loadExternalScripts();
  } catch (missing) {
    logger.logError(missing, {
      message: `Failed to load dependencies ${missing}`,
      component: namespace,
    });
  }
  afterDomContentLoaded(async () => {
    try {
      const { default: hydrate } = await import('./hydrate');
      hydrate();
    } catch (err) {
      //
    }
  });
  try {
    const { props } = window.__internal;
    delete window.__internal.props;
    afterDomContentLoaded(() => afterPageLoad(props));
  } catch (err) {
    logger.logError(err, {
      message: `Failed to initialise client side ${err}`,
      component: namespace,
    });
  }
})();
